<script>
	import { fade, fly } from 'svelte/transition';

	let greeting = false;
	let poetry = false;
	let contact = false;

	setTimeout(() => { greeting = true }, 8400)
	setTimeout(() => { greeting = false }, 21000)

	function setPoetry(see) {
		poetry = see;
		greeting = false;
		setTimeout(() => { poetry = false }, 7300)
	}
</script>

<h1>nyctoǔ</h1>
<h3>ʇǝu</h3>
<h4>Ð—Ð´ÐµÑÑŒ Ð¿ÑƒÑÑ‚Ð¾</h4>
<h2>ÐÐ¸Ñ‡ÐµÐ³Ð¾ Ð½ÐµÑ‚</h2>

<i on:click={() => greeting = true}>.</i>
{#if contact}
	<a href="mailto:ai@nyctou.net">ai at at me</a>
{/if}

{#if greeting}
	<pre class="greetings" in:fly="{{ y: 210, duration: 4200 }}" out:fade>

		ow hello stranger..
			you are welcomed here ;з

		.

			..

			would you like to hear my poetry?
		<b on:click={() => setPoetry(true)}>yes</b> | <b on:click={() => setPoetry(false)}>yes</b>
	</pre>
{/if}

{#if poetry}
	<pre class="poetry" in:fly="{{ y: 330, duration: 3300 }}" out:fade>

		the time is now
			there is only a moment
				please be
	</pre>
{/if}

<style>
	h1, h3, h4, h2 {
		margin: 0;
	}
	.greetings {
		word-break: keep-all;
	}
	.greetings b {
		cursor: pointer;
	}
</style>